$(document).ready(function () {
    initItemCarousels();
});

function resize() {

}

var timo;
window.onresize = function () {
    clearTimeout(timo);
    timo = setTimeout(resize, 100);
};

$('.navbar-toggler').click(function(){
    $(this).toggleClass('active');
});

function initItemCarousels() {
    var carousels = $('.item-carousel');

    carousels.each(function () {
        var wrap = $(this).closest('.carousel-wrap');
        var owl = $(this).addClass('owl-carousel').owlCarousel({
            autoWidth: false,
            loop: true,
            controls: true,
            dots: false,
            autoplay: true,
            autoplayTimeout: 3000,
            items: 5,
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 2
                },
                768: {
                    items: 3
                },
                991: {
                    items: 4
                },
                1200: {
                    items: 5
                }
            }
        });

        wrap.find('.c-control').click(function () {
            if ($(this).hasClass('carousel-prev')) {
                owl.trigger('prev.owl.carousel');
            } else {
                owl.trigger('next.owl.carousel');
            }
            return false;
        });
    });
}
